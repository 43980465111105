(() => {
  'use strict';

  // Const
  // -----
  const MAX_VIEWPORT_WIDTH = 1920;

  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_SMALL_TABLET_WIDTH = 900;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1366;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('header');
  window.$HEADER_MENU = $('.header-menu');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  $WINDOW
    .on('calcSizes', () => {
      window.VIEWPORT_WIDTH = document.documentElement.clientWidth || Math.round(window.visualViewport.width) || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.height();
    })
    .on('resize', () => {
      $WINDOW.trigger('calcSizes');
    })
    .trigger('calcSizes');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = () => VIEWPORT_WIDTH > DESKTOP_WIDE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = () => VIEWPORT_WIDTH >= DESKTOP_WIDTH;
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = () => (VIEWPORT_WIDTH >= PORTRAIT_TABLET_WIDTH && VIEWPORT_WIDTH < DESKTOP_WIDTH);
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = () => VIEWPORT_WIDTH < PORTRAIT_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_SMALL_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_SMALL_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = () => VIEWPORT_WIDTH < SMALL_MOBILE_WIDTH;


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '\\+7 \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__'
    },
    'number-mask': {
      mask: '9{*}',
      greedy: false
    },
    'float-mask': {
      mask: '9{*}[(.|,)9{1,2}]',
      greedy: false
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');

  // Number
  $('.js-number-mask').inputmask('number-mask');

  // Float number
  $('.js-float-mask').inputmask('float-mask');



  $WINDOW
    .one('load', () => {
      $WINDOW.trigger('calcSizes');
      $DOCUMENT.trigger('headerEvents');
    })
    .on('structureScale', () => {
      $('.structure').css('transform', (VIEWPORT_WIDTH > MAX_VIEWPORT_WIDTH ? 'scale('.concat(VIEWPORT_WIDTH / MAX_VIEWPORT_WIDTH, ')') : ''));
    })
    .on('resize', () => {
      $WINDOW.trigger('structureScale');
      $DOCUMENT.trigger('headerEvents');
    })
    .trigger('structureScale');


  $DOCUMENT
    .ready(() => {
      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', e => {
          e.currentTarget.checked ? $BODY.addClass('menu-is-open') : $BODY.removeClass('menu-is-open');
        })
        .prop({'checked': false, 'disabled': false})
        .trigger('change');


      // Lazy load
      // ---------
      $('.vitrine-item-image [data-lazy-src], .video-frame > a, .gallery img')
        .unveil({
          attribute: 'lazySrc',
          placeholder: '',
          offset: 50,
          throttle: 200
        });
    })

    // Scroll to
    // ---------
    .on('scrollTo', (e, $element, speed, offset) => {
      if ($element === undefined)
        $element = $(e.target);

      if (speed === undefined)
        speed = $element.data('scrollSpeed') || 200;

      if (offset === undefined)
        offset = $element.data('scrollOffset') || 0;

      $WINDOW.scrollTo($element, {axis: 'y', duration: speed, offset: parseInt(offset) - (IS_DESKTOP_WIDTH() ? 0 : HEADER_HEIGHT)});
    })
    .on('click.jsScrollTo', '.js-scroll-to', e => {
      e.preventDefault();

      let $link = $(e.currentTarget),
          $element = $($link.attr('href').substring($link.attr('href').lastIndexOf('#'))),
          speed = $link.data('scrollSpeed') || 150,
          offset = $link.data('scrollOffset') || undefined;

      $DOCUMENT.trigger('scrollTo', [$element, speed, offset]);
    })

    // Header events
    // -------------
    .on('headerEvents', () => {
      if (IS_DESKTOP_WIDTH()) {
        if (!$BODY.hasClass('is-desktop-events')) {
          $BODY
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events');

          // Header menu desktop events
          // --------------------------
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .find('li').removeClass('is-hovered')
            .find('ul').removeAttr('style');

          // Fix header submenu popup position
          // ---------------------------------
          let headerMenuWidth = $HEADER_MENU.outerWidth();

          $HEADER_MENU.find('> ul > li').each((i, element) => {
            let $item = $(element),
                $submenu = $item.find('> div > ul');

            if ($submenu.length) {
              $submenu.css('max-width', headerMenuWidth);

              let position = headerMenuWidth - $submenu.outerWidth() - $item.position().left;

              if (position < 0)
                $submenu.css('left', position);
            }
          });
        }
      }
      else {
        if (!$BODY.hasClass('is-mobile-events')) {
          $BODY
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          // Header menu mobile events
          // -------------------------
          $HEADER_MENU
            .find('ul').removeAttr('style');

          $DOCUMENT
            // Close header menu
            .on('click.closeHeaderMenu', e => {
              if (!($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu').length)) {
                e.stopPropagation();

                $MENU_CHECKBOX.prop('checked', false).trigger('change');
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu > ul a, .header-menu > ul span, .header-menu > ul .dropmarker', e => {
              e.preventDefault();

              let $self = $(e.currentTarget),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              $parent.siblings().removeClass('is-hovered').find('> div > ul').slideUp(300);

              if (isWithDropdown) {
                if (isOnHover) {
                  if ($self.prop('tagName').toUpperCase() === 'A')
                    location.href = $self.prop('href');
                  else
                    $parent.removeClass('is-hovered').find('> div > ul').slideUp(300);
                }
                else
                  $parent.addClass('is-hovered').find('> div > ul').slideDown(300);
              }
              else {
                if ($self.prop('tagName').toUpperCase() === 'A')
                  location.href = $self.prop('href');
              }
            });
        }
      }
    })


    // Callback form open
    // ------------------
    .on('click.callbackFormButton', '.callback-btn', e => {
      e.preventDefault();

      $.fancybox.open({
        type: 'inline',
        src: '#callback-popup-form',
      });
    })


    // Video frame load
    // ----------------
    .on('click.videoFrame', '.video-frame > a', e => {
      e.preventDefault();

      let $self = $(e.currentTarget),
          $iframe = $self.next();

      $iframe.attr('src', () => $iframe.data('src'));

      if ($self.data('popup') !== undefined)
        $.fancybox.open($iframe);
      else
        $iframe.parent().addClass('active');
    })


    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', e => {
      e.preventDefault();

      let $self = $(e.currentTarget);

      if (!$self.hasClass('active')) {
        let $container = $self.closest('.js-tabs'),
            $links = $container.find('a'),
            $destination = !!$container.data('tabsDest') ? $($container.data('tabsDest')) : $container.next(),
            cssClass = $container.data('tabCssClass') || 'active';

        $self.addClass('active');
        $links.not($self).removeClass('active');
        $destination.children().removeClass(cssClass).eq($links.index($self)).addClass(cssClass);
      }
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: (widget, current) => {
      switch (current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: (widget, current) => {
      if (current.contentType == 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH())
        current.$content.find('.js-autofocus-inp').trigger('focus');
    }
  });
})();